<template>
	<div>
		<div id="op_content_box"></div>
	</div>
</template>

<script>
export default {
	data: () => ({
		show: false,
		secraScript: null,
		loadOptions: {
			module: 'op-frontend-suche',
			content_id: 'op_content_box',
			options: {
				hasOwnCookieInfo: true,
			}
		}
	}),
	mounted() {
		if(typeof secra_op_client == 'undefined'){
			this.secraScript = document.createElement('script')
			this.secraScript.setAttribute('src', 'https://www.optimale-praesentation.de/frontend/js/bin/boot?secratoid=503396c43')
			document.head.appendChild(this.secraScript)

			this.secraScript.addEventListener('load', () => {
				this.loadModule();
			});
		}
		else{
			/*wenn wir hier sind, dann wurde die Seite schon mal geladen - d.h. das funktioniert so leider nicht nochmal. Sehr nervig - als lösung laden wir die komplette Seite nochmal neu. Nicht die beste Lösung aber für den Moment funktioniert das ...*/
			location.reload();
		}
	},
	methods: {
		loadModule() {
			if (typeof secra_op_client !== 'undefined' && secra_op_client.loadModule) {
				secra_op_client.loadModule(this.loadOptions.module, this.loadOptions.content_id, this.loadOptions.options);
			} else {
				if (!secra_op_client) {
					secra_op_client = {};
				}
				secra_op_client.loadQueue = secra_op_client.loadQueue || [];
				secra_op_client.loadQueue.push({
					module: this.loadOptions.module,
					embed_id: this.loadOptions.content_id,
					options: this.loadOptions.options,
				});
			}
		}
	},
	/*beforeDestroy() {
		if (this.secraScript) {
			console.log("removing SCript");
			document.head.removeChild(this.secraScript);
			console.log("script removed");
		}
	}*/
};
</script>
